<template>
  <div class="KeenMind">
    <div v-loading="loading"></div>
    <swiper :banners="banners"></swiper>
    <div class="keenMind-content">
      <div class="crumb">
        <breadcrumb :refer="refer"></breadcrumb>
      </div>
      <h1 v-if="clientWidth > 750" class="keenMind-title">剑 鸣</h1>
      <div class="input-search">
        <el-input v-model.trim="GetActivity.title" type="text" @keyup.enter.native="Search"
          placeholder="请输入您要查询的内容"></el-input>
        <i class="el-icon-search cur" @click="Search"></i>
      </div>
      <div class="content-nav">
        <el-form ref="form" :model="sizeForm" label-width="60px" size="mini">
          <el-form-item label="行业：">
            <el-radio-group v-model="sizeForm.industryId" size="medium" @change="changeRadio">
              <el-radio-button v-for="item in Modules.industrys" :key="item.value" :label="item.value"
                :value="item.value" border class="check-item">{{ item.text }}</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="二级：" v-if="selectList.length > 0">
            <el-radio-group v-model="sizeForm.industryIdSecond" size="medium" @change="changeSecond">
              <el-radio-button v-for="item in selectList" :key="item.value" :label="item.value" class="check-item">{{
      item.text }}</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="类型：">
            <el-radio-group v-model="sizeForm.type" size="medium" @change="changeRecommend">
              <el-radio-button v-for="item in recommend" :key="item.value" :label="item.text" :value="item.value"
                class="check-item"></el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div class="MyRegistration tr violet cur" @click="MyRegistration">我的报名</div>
      </div>
      <div v-if="!Isdata" class="keenList">
        <div v-for="(item, index) in keenList.data" :key="item.id" class="keenList-item flex"
          :class="'keenList-item' + (index + 1)">
          <div class="keenList-item-img cur" @click="goDetail(item.id)">
            <img v-if="item.imgUrl" :src="item.imgUrl" alt="">
            <img v-else src="../../assets/PC/keenMind/list0.jpg" alt="">
          </div>
          <div class="keenList-item-text">
            <div class="keenList-item-text-title flex3">
              <div v-if="clientWidth < 751" class="keenList-item-text-state-mb flex">
                <div v-if="item.status == 0" class="co-999">未开始</div>
                <div v-if="item.status == 1">
                  <a v-if="item.status == 1 && item.type == 0 && item.redirectUrl" :href="item.redirectUrl"
                    class="redirectUrl">进入活动</a>
                  <div v-else class="co-voi">进行中</div>
                </div>
                <div v-if="item.status == 2">
                  <div v-if="item.playback == 0" class="co-999">已结束</div>
                  <p v-if="item.playback == 1" class="redirectUrl" @click="down(item.id)">观看回放</p>
                  <p v-if="item.playback == 2" class="redirectUrl" @click="down(item.id)">下载资料</p>
                </div>
              </div>
              <div v-if="item.classify != 1 && item.classify != 3">
                <h1 v-if="item.type == 0" class="hidden cur" @click="goDetail(item.id)">【线上】{{ item.title }}</h1>
                <h1 v-else class="hidden cur" @click="goDetail(item.id)">【线下】{{ item.title }}</h1>
              </div>
              <div v-else>
                <h1 class="keenMindDetail-title cur" @click="goDetail(item.id)">{{ item.title }}</h1>
              </div>
              <div v-if="clientWidth > 750" class="keenList-item-text-state flex">
                <div v-if="item.status == 0" class="co-999">未开始</div>
                <div v-if="item.status == 1">
                  <a v-if="item.status == 1 && item.type == 0 && item.redirectUrl" :href="item.redirectUrl"
                    class="redirectUrl" target="_blank">进入活动</a>
                  <div v-else class="co-voi">进行中</div>
                </div>
                <div v-if="item.status == 2">
                  <div v-if="item.playback == 0" class="co-999">已结束</div>
                  <p v-if="item.playback == 1" class="redirectUrl cur" @click="down(item.id)">观看回放</p>
                  <p v-if="item.playback == 2" class="redirectUrl cur" @click="down(item.id)">下载资料</p>
                </div>
              </div>
            </div>
            <div class="keenList-item-text-date co-999">活动时间：{{ item.startTimeStr }} - {{ item.endTimeStr }}</div>
            <div class="keenList-item-text-introduction co-999 cur" @click="goDetail(item.id)"> {{ item.introduction }}
            </div>
            <div class="oper-btn flex2">
              <div class="glance" title="浏览量">
                <img src="@/assets/PC/studyF/study_type_k.png" />
                <span class="ml"> {{ item.pv }} </span>
              </div>
              <div class="collect cur" title="收藏">
                <img v-if="!item.isCollect" src="@/assets/PC/studyF/study_type_xk.png"
                  @click="collect(item.id, item.isCollect, index)" />
                <img v-else src="@/assets/PC/studyF/study_type_xs.png" @click="collect(item.id, item.isCollect, index)"
                  class="animate__animated animate__fadeIn" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="noData">
        <div class="co-999" style="margin-bottom: 15px">一大波活动正在路上！</div>
        <img src="@/assets/PC/keenMind/keen-null.png" alt="" style="margin-bottom: 20px" />
      </div>
      <div v-if="IsloadList" class="loadList flex0">
        <div class="loadList0" @click="loadlist">加载更多</div>
      </div>
      <div v-if="clientWidth < 750 && !IsloadList && keenList.data.length > 0" class="tc notDetail">
        <div>
          没有更多内容了~
          <span class="violet" @click="scrollTop">返回顶部</span>
        </div>
      </div>
      <div class="page tr" v-if="!Isdata">
        <el-pagination @current-change="handleCurrentChange" :total="keenList.recordCount"
          :page-size="keenList.pageSize" :current-page="$route.query.page > 0 ? $route.query.page * 1 : 1" background
          layout="pager, next" next-text="下一页" hide-on-single-page>
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import swiper from "@/components/Swiper.vue";
import { GetBanner } from '@/api/Home.js'
import breadcrumb from "@/components/Breadcrumb/index.vue";
import * as Keen from '@/api/KeenMind.js';
import { debounce, scrollTop, GetWxShares } from "@/utils/common";
import { dataState } from "@/api/all.js";

export default {
  name: "keenMind",
  data() {
    return {
      refer: [
        { name: "首页", href: "/" },
        { name: "剑鸣" },
      ],
      banners: [],                        // !banner图
      loading: true,                      // !loading
      recommend: [
        { value: "-1", text: "全部", list: [] },
        { value: "0", text: "线上", list: [] },
        { value: "1", text: "线下", list: [] }
      ],
      selectList: [],
      sizeForm: {
        industryId: '0',
        industryIdSecond: '0',
        type: '全部',
      },
      GetActivity: {
        industryId: 0, // !行业Id
        industryIdSecond: 0,
        type: -1,      // !类型Id
        page: 1,       // !页码
        limit: 3,      // !条数
        title: ''      // !搜索条件
      },
      keenList: {
        data: [],
        recordCount: 0,
      },
      IsloadList: false,                  // !移动端加载更多
      Isdata: false,                      // !暂无数据
    };
  },
  mounted() {
    const share = {
      title: '剑鸣 | 剑盟社区',
      desc: ''
    }
    GetWxShares(share);
    // !获取banner图
    GetBanner().then(res => {
      const windowWidth = document.documentElement.clientWidth;
      if (windowWidth > 750) {
        this.banners = res.data.activity;
      } else {
        this.banners = []
      }
    });

    if (this.$route.query.page > 0 && this.clientWidth > 750) {
      this.GetActivity.page = this.$route.query.page;
    } else {
      this.GetActivity.page = 1;
    }
    this.init();
    // 数据统计
    const data = {
      "visitType": 1,
      "gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
      "url": window.location.href,
      "pageUrl": "keenMind",
      "relationId": '',
    }
    dataState(data).then(res => {
      sessionStorage.setItem('gid', res.data);
    });
  },
  methods: {
    // !搜索
    Search() {
      this.loading = true;

      this.GetActivity.page = 1;
      this.GetActivity.limit = 5;
      this.init();
    },
    // !初始化列表
    init(Ismobile) {
      Keen.GetActivities(this.GetActivity).then(res => {
        if (res.status == 1) {
          if (res.data.length > 0) {
            this.Isdata = false;
            if (res.data.length < res.recordCount) {
              this.IsloadList = true;
            } else {
              this.IsloadList = false;
            }
            if (Ismobile && this.clientWidth < 750) {
              this.keenList.data = this.keenList.data.concat(res.data);
              if (this.keenList.data.length == res.recordCount) {
                this.IsloadList = false;
              }
            } else {
              this.keenList = res;
            }
          } else {
            this.keenList = { data: [], recordCount: 0, };
            this.IsloadList = false;
            this.Isdata = true;
          }

        } else {
          this.$toast(res.message);
        }
        this.loading = false;
      })
    },
    // !行业筛选
    changeRadio(id) {
      console.log(id)
      if (id != '0') {
        this.selectList = this.filterSelect(id)
      } else {
        this.selectName = ''
        this.selectList = []
      }
      this.loading = true;
      this.GetActivity.page = 1;
      this.GetActivity.industryId = id
      // this.GetActivity.industryId = (this.Modules.industrys.filter(v => v.text == text))[0].value * 1;
      this.GetActivity.title = '';
      this.init();
    },
    // !行业二级筛选
    changeSecond(id) {
      this.GetActivity.page = 1;
      this.GetActivity.industryIdSecond = id
      this.GetActivity.title = '';
      this.init();
    },
    // !推荐筛选
    changeRecommend(text) {
      this.loading = true;
      this.GetActivity.page = 1;
      this.GetActivity.type = (this.recommend.filter(v => v.text == text))[0].value * 1;
      this.GetActivity.title = '';
      this.init();
    },
    filterSelect(id) {
      var arr1 = [{
        value: '0',
        text: '全部'
      }]
      var arr2 = []
      this.Modules.industrys.filter(item => {
        if (item.value == id) {
          arr2 = item.list
        }
      })
      return arr1.concat(arr2)
    },
    // !页数
    handleCurrentChange: function (currentPage) {
      this.loading = true;
      this.$router.push('/Home/keenMind?page=' + currentPage);
    },
    // !移动端加载更多
    loadlist() {
      if (this.clientWidth < 750) {
        this.loading = true;
        this.GetActivity.page++;
        this.init(true);
      }
    },
    // !收藏与取消收藏
    collect(id, state, index) {
      if (this.$store.state.token) {
        const Id = { 'activityId': id }
        debounce(() => {
          if (!state) {
            Keen.KeenCollect(Id).then(res => {
              this.$toast(res.message);
              if (res.status == 1) {
                this.keenList.data[index].isCollect = true;
              } else {

              }
            })
          } else {
            Keen.KeenUnCollect(Id).then(res => {
              this.$toast(res.message);
              if (res.status == 1) {
                this.keenList.data[index].isCollect = false;
              } else {

              }
            })
          }
        }, 1000)
      } else {
        this.$toast('请登录后操作!');
      }
    },
    // !返回顶部
    scrollTop() {
      scrollTop()
    },
    // !去详情页
    goDetail(id) {
      this.$router.push('/Home/keenMindDetail?id=' + id);
    },
    // !去我的报名页面
    MyRegistration() {
      if (this.$store.state.token) {
        this.$router.push('/Home/Profile/MyRegistration');
      } else {
        this.$toast('请登录后再操作！')
      }
    },
    // !回放与下载资料
    down(id) {
      this.$router.push('/Home/keenDown?id=' + id);
    }
  },
  computed: {
    clientWidth() {
      return this.$store.state.clientWidth;
    },
    Modules() {
      return this.$store.getters.module;
    }
  },
  watch: {
    $route: {
      handler(val, oldval) {
        if (this.$route.query.page > 0 && this.clientWidth > 750) {
          this.GetActivity.page = this.$route.query.page * 1;
        } else {
          this.GetActivity.page = 1;
        }
        this.init();
      }
    }
  },
  components: {
    swiper,
    breadcrumb,
  },
};
</script>

<style lang="scss">
$mobil_base: 750;

@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}

.keenMind-content {
  margin: 0 auto;
  max-width: 1200px;

  .keenMind-title {
    margin-bottom: 17px;
  }

  .input-search {
    position: relative;
    margin-bottom: 15px;
  }

  .input-search i {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 18px;
    color: #ccc;
  }

  .content-nav label {
    line-height: 36px;
  }

  .MyRegistration {
    font-size: 16px;
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 20px;
  }

  .keenList-item {
    padding: 57px 41px;
    border: 1px #ccc solid;
    transition: all 0.3s;
  }

  .keenList-item-img {
    width: 365px;
    height: 240px;
    overflow: hidden;
    margin-right: 30px;
    min-width: 365px;
  }

  .keenList-item-img img,
  .keenList-item-text {
    width: 100%;
  }

  .keenList-item-text-title {
    margin-bottom: 15px;
  }

  .keenList-item-text h1 {
    font-size: 19px;
    height: 26px;
    max-width: 650px;
  }

  .keenList-item-text-state {
    width: auto;
    white-space: nowrap;
  }

  .keenList-item-text-state div {
    font-size: 17px;
  }

  .redirectUrl {
    background: #6b18a8;
    color: #fff !important;
    padding: 2px 10px;
    border-radius: 16.5px;
    font-size: 14px;
  }

  .keenList-item-text-date {
    margin-bottom: 23px;
    font-size: 15px;
  }

  .keenList-item-text-introduction {
    margin-bottom: 40px;
    line-height: 26px;
    height: 52px;
    font-size: 16px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .glance {
    margin-right: 40px;
  }

  .glance img {
    width: 17px;
    height: 21px;
  }

  .collect {
    position: relative;
    width: 21px;
    height: 18px;
  }

  .collect img {
    width: 21px;
    height: 18px;
    position: absolute;
    left: 0;
    top: 0;
  }

  .page {
    margin: 40px 0 75px 0;
  }
}

@media (min-width: 750px) {
  .keenList-item:hover {
    box-shadow: 0 0 15px #ccc;
    border: 1px #FEFEFE solid;
  }
}


@media (max-width: 750px) {
  .keenMind-content {
    margin: 0 vw(40);

    .input-search i {
      top: vw(16);
      right: vw(14);
      font-size: vw(36);
    }

    .content-nav label {
      line-height: 28px;
    }

    .keenList-item {
      padding: 0;
      display: block;
      background: #F6F8FA;
      border: 0;
      margin-bottom: vw(42);
    }

    .keenList-item-img {
      width: 100%;
      min-width: 100%;
      height: auto;
    }

    .keenList-item-text {
      margin: 0 vw(40);
      width: auto;
    }

    .keenList-item-text-title {
      margin-bottom: vw(16);
      display: block;
    }

    .keenList-item-text h1 {
      font-size: vw(24);
      height: auto;
    }

    .keenList-item-text-state {
      display: none;
    }

    .keenList-item-text-state-mb {
      margin: vw(41) 0 vw(31) 0;
    }

    .keenList-item-text-date {
      margin-bottom: vw(24);
      font-size: vw(22);
    }

    .keenList-item-text-introduction {
      font-size: vw(24);
      line-height: vw(36);
      height: auto;
      margin-bottom: vw(40);
    }

    .oper-btn {
      padding-bottom: vw(40);
    }
  }
}
</style>